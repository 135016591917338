import { createStore } from 'vuex';

export default createStore({
  state: {
    modals: {
      test: false,
      grow: false,
      certified: false,
      fire: false,
      forest: false,
      rareAnimals: false,
      tasks: false,
      treeUsage: false,
      responsibility: false,
      care: false,
    },
    windowScale: '1',
    windowWidth: null,
    preventScroll: event => {
      console.log('scroll prevented');
      event.prevent();
    },
  },
  getters: {
    isModalOpened: state => Object.values(state.modals).some(Boolean),
    isDesktop: state => (state.windowWidth * state.windowScale) >= 1052,
  },
  mutations: {
    $m_openModal: (state, modal) => {
      state.modals[modal] = true;
      document.body.addEventListener('scroll', state.preventScroll);
      document.querySelector('html').classList.add('modal-opened');
      document.querySelector('body').classList.add('modal-opened');
      document.querySelector('#app').classList.add('modal-opened');
    },
    $m_closeModal: (state, modal) => {
      state.modals[modal] = false;
      document.body.removeEventListener('scroll', state.preventScroll);
      document.querySelector('html').classList.remove('modal-opened');
      document.querySelector('body').classList.remove('modal-opened');
      document.querySelector('#app').classList.remove('modal-opened');
    },
    $m_setWindowScale: (state, windowScale) => {
      state.windowScale = windowScale;
    },
    $m_setWindowWidth: (state, windowWidth) => {
      state.windowWidth = windowWidth;
    },
  },
  actions: {
  },
  modules: {
  },
});
