<template>
  <div class="disclaimer">
    <div class="disclaimer__text">
      Продолжая использовать наш сайт, вы соглашаетесь на обработку файлов cookie и использование
      других технологий сбора информации в соответствии с
      <a href="/privacy" target="_blank">Политикой в отношении обработки Персональных данных</a> для обеспечения правильной работы сайта и предоставления лучшего пользовательского опыта.
    </div>
    <div class="disclaimer__button">
      <button class="text-bold" @click="$emit('user-agreed')">Принять</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Disclaimer',
};
</script>

<style scoped lang="scss">
.disclaimer {
  z-index: 999999;
  position: fixed;
  bottom: 0;
  left: 0;

  color: #ffffff;
  background-color: #334D23;
  padding: 9px;
  @media (min-width: 1052px) {
    display: flex;
    padding: 20px;
  }
  &__text {
    font-size: 12px;
    line-height: 16px;
    @media (min-width: 1052px) {
      margin-right: 50px;
      font-size: 14px;
      line-height: 17px;
    }
  }
  &__button {
    margin-top: 9px;

    display: flex;
    justify-content: flex-end;
    font-size: 14px;
    @media (min-width: 1052px) {
      align-self: center;
      margin: 0;
    }
    button {
      cursor: pointer;
      $bgc: #55853C;
      padding: 5px 20px;
      border-radius: 20px;
      color: #ffffff;
      background-color: $bgc;
      border: 1px solid #A3BB3A;
      transition: 0.4s ease-out;
      @media (min-width: 1052px) {
        padding: 8px 38px;
        font-size: 18px;
      }
      &:hover {
        background-color: lighten($bgc, 5%);
      }
    }
  }
}
a,
a:link,
a:visited,
a:hover,
a:active {
  color: #ffffff;
}
</style>
