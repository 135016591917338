<template>
  <router-view />
</template>

<style lang="scss">
  .modal-opened {
    overflow-y: hidden !important;
    transform: none !important;
  }

  .nowrap {
    white-space: nowrap;
  }

</style>
