<template>
  <teleport to="body">
    <transition name="fade">
      <Disclaimer v-if="disclaimerUp" @user-agreed="disclaimerAgreed" />
    </transition>
  </teleport>

  <teleport to="body">
    <transition name="fade">
      <div
        v-if="!preloader.contentLoaded"
        ref="preloader"
        class="preloader"
      >
        <div class="preloader-inner">
          <div class="loader">
            <div class="tree-full tree">
              <img src="/images/loader/tree-full.svg" alt="tree-full">
            </div>
            <div ref="treeEmpty" class="tree-empty tree">
              <img src="/images/loader/tree-empty.svg" alt="tree-empty">
            </div>
            <div
              :key="'preloader' + roundedPercentage"
              :class="{'hide-percentage': roundedPercentage === 0}"
              class="percentage"
            >
              {{ roundedPercentage }}%
            </div>
          </div>
        </div>
      </div>
    </transition>
  </teleport>

  <div v-if="preloader.contentLoaded" class="clouds parallax" data-depth='0.60'>
    <img
      v-if="isDesktop"
      class="desktop"
      src="@/assets/images/home/parallax/parallax_clouds_desktop.svg"
      alt="clouds"
    >
    <img
      v-else
      class="mobile"
      src="@/assets/images/home/parallax/parallax_clouds_mobile.svg"
      alt="clouds"
    >
  </div>
  <div class="trees-bottom parallax" data-depth='0.80'>
    <img
      v-if="isDesktop"
      class="desktop"
      src="@/assets/images/home/parallax/parallax_trees_desktop_squeezed.png"
      alt="trees"
    >
  </div>
  <template v-if="preloader.showPage">
    <router-view />
  </template>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import gsap from 'gsap';
import Cookies from 'js-cookie';
import logoImg from '@/assets/images/home/logo.svg';
import topLogo from '@/assets/images/home/top_logo.png';
import taglineImg from '@/assets/images/home/tagline.svg';
import woodpeckerImg from '@/assets/images/home/animations/woodpecker_squeezed.png';
import eyes1Img from '@/assets/images/home/animations/eyes1.png';
import eyes2Img from '@/assets/images/home/animations/eyes2.png';
import bird1Img from '@/assets/images/home/animations/bird1.png';
import cloudsDesktopImg from '@/assets/images/home/parallax/parallax_clouds_desktop.svg';
import cloudsMobileImg from '@/assets/images/home/parallax/parallax_clouds_mobile.svg';
import treeDesktopImg from '@/assets/images/home/tree_desktop-5.png';
import treeMobileImg from '@/assets/images/home/tree_mobile-5.png';
import bgImg from '@/assets/images/home/bg.png';
import Disclaimer from '@components/Disclaimer';

// eslint-disable-next-line func-names
Image.prototype.load = function(url) {
  const thisImg = this;
  const xmlHTTP = new XMLHttpRequest();
  xmlHTTP.open('GET', url, true);
  xmlHTTP.responseType = 'arraybuffer';
  // eslint-disable-next-line func-names
  xmlHTTP.onload = function() {
    const blob = new Blob([this.response]);
    thisImg.src = window.URL.createObjectURL(blob);
  };
  xmlHTTP.onprogress = e => {
    thisImg.completedPercentage = parseInt((e.loaded / e.total) * 100, 10);
  };
  xmlHTTP.onloadstart = () => {
    thisImg.completedPercentage = 0;
  };
  xmlHTTP.send();
};
Image.prototype.completedPercentage = 0;

// eslint-disable-next-line no-undef
gsap.registerPlugin(ScrollTrigger);

export default {
  name: 'Main',
  components: { Disclaimer },
  data: () => ({
    tl: null,
    disclaimerUp: false,
    preloader: {
      contentLoaded: false,
      showPage: false,
      percentage: 0,
      animation: null,
      percentageAnimation: null,
    },
    preloadImages: {
      mobile: [
        logoImg,
        topLogo,
        taglineImg,
        woodpeckerImg,
        eyes1Img,
        eyes2Img,
        bird1Img,
        cloudsMobileImg,
        treeMobileImg,
        bgImg,
      ],
      desktop: [
        logoImg,
        topLogo,
        taglineImg,
        woodpeckerImg,
        eyes1Img,
        eyes2Img,
        bird1Img,
        cloudsDesktopImg,
        treeDesktopImg,
        bgImg,
      ],
    },
    preloadedImages: [],
  }),
  computed: {
    ...mapGetters(['isDesktop']),
    ...mapState(['windowScale']),
    roundedPercentage() {
      return this.preloader.percentage.toFixed(0);
    },
  },
  created() {
    document.querySelector('html').classList.add('prevent-scroll');
    this.checkDisclaimerCookie();
  },
  mounted() {
    this.$m_setWindowWidth(window.innerWidth);

    window.addEventListener('resize', this.onLoad);
    // this.waitContentLoad(this.onLoad);
    this.onLoad();
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.onLoad);
  },
  methods: {
    ...mapMutations(['$m_setWindowWidth']),
    waitContentLoad(cb) {
      this.preloadedImages = this.isDesktop ? this.preloadImages.desktop : this.preloadImages.mobile;
      // this.preloadedImages = [treeDesktopImg];

      this.preloadedImages = this.preloadedImages.map(imgSrc => {
        const img = new Image();
        img.src = imgSrc;
        return img;
      });

      // this.preloadedImages.forEach((img, index) => {
      //   img.addEventListener('load', () => {
      //     console.log('loaded: ', index);
      //     loadedImagesCount++;
      //     if (loadedImagesCount === this.preloadedImages.length) {
      //       console.log('show');
      //       this.preloader.contentLoaded = true;
      //       this.$nextTick(cb);
      //     }
      //   });
      // });
      let loadedImagesCount = 0;

      this.preloadedImages.forEach(img => {
        img.addEventListener('load', () => {
          loadedImagesCount++;
          const percentage = Math.round((loadedImagesCount / this.preloadedImages.length) * 100);
          if (percentage >= 50) document.querySelector('body').classList.add('half-loaded');

          const duration = percentage === 100 ? 0.1 : 2;

          if (this.preloader.animation !== null) {
            this.preloader.animation.kill();
          }
          if (this.preloader.percentageAnimation !== null) {
            this.preloader.percentageAnimation.kill();
          }

          this.preloader.percentageAnimation = gsap.to(this.preloader, { percentage, duration });

          this.preloader.animation = gsap.to(this.$refs.treeEmpty, {
            height: `${100 - percentage}%`,
            duration,
            onComplete: () => {
              if (loadedImagesCount !== this.preloadedImages.length) return;

              document.querySelector('html').classList.remove('prevent-scroll');
              this.preloader.contentLoaded = true;
              this.$nextTick(cb);
            },
          });

          if (percentage !== 100) return;
          this.preloader.showPage = true;
        });
      });
    },
    onLoad() {
      if (this.tl !== null) {
        this.tl.kill();
      }

      this.waitContentLoad(() => {
        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: '.home',
            start: 'top top',
            end: 'bottom bottom',
            scrub: true,
          },
        });

        gsap.utils.toArray('.parallax').forEach(layer => {
          const { depth } = layer.dataset;
          const movement = -(layer.offsetHeight * depth);
          gsap.set(layer, { y: 0 });
          tl.to(layer, { y: movement, ease: 'none' }, 0);
        });

        this.tl = tl;
        // this.preloader.contentLoaded = true;
      });
    },
    onResize() {
      this.onLoad();
    },
    checkDisclaimerCookie() {
      const cookie = Cookies.get('disclaimer');

      this.disclaimerUp = cookie !== 'true';
    },
    disclaimerAgreed() {
      Cookies.set('disclaimer', 'true', { secure: true, expires: 365 });
      this.disclaimerUp = false;
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/styles/main.scss";

.clouds {
  position: absolute;
  z-index: 0;
  //width: 100vw;
  width: 530px;
  top: 550px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 100%;

  .desktop {
    display: none;
  }

  img {
    display: block;
    height: auto;
    width: 100%;
    max-width: 100%;
  }

  @media (min-width: 1052px) {
    width: 1078px;
    top: 500px;
    .desktop {
      display: block;
    }
    .mobile {
      display: none;
    }
  }
}

.trees-bottom {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);

  img {
    display: block;
    height: auto;
    width: 100%;
    max-width: 100%;
  }

  .desktop {
    display: none;
  }

  @media (min-width: 1052px) {
    width: 1355px;
    bottom: -640px;
    .desktop {
      display: block;
    }
    .mobile {
      display: none;
    }
  }
}

.preloader {
  z-index: 99999;
  top: 0;
  left: 0;
  position: fixed;
  background-color: #fff;
  height: 100vh;
  width: 100%;

  .preloader-inner {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.4s ease-out;

    .loader {
      position: relative;
      @media (min-width: 2000px) {
        transform: scale(1.8);
      }

      .tree {
        overflow: hidden;
        width: 160px;
        @media (min-width: 1052px) {
          width: 280px;
        }

        img {
          width: 100%;
          height: auto;
          max-width: 100%;
        }
      }

      .tree-full {
      }

      .tree-empty {
        position: absolute;
        left: 0;
        top: 0;
      }

      .percentage {
        margin-top: 5px;
        text-align: center;
        color: #808936;
        font-weight: bold;
        @media (min-width: 1052px) {
          font-size: 24px;
        }
      }
    }

    .hide-percentage {
      opacity: 0;
    }
  }
}

</style>
